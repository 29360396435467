
import { ComputedRef, defineComponent, PropType, computed } from "vue";
import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { sortItem, sortOrders } from "@/enums/main/sortOrders";
import {
  infoWithImage,
  ITableViewConfiguration
} from "@/components/Table/ts/interfaces/common";
import { userId } from "@/store/interfaces/auth/common";
import AppTable from "@/components/Table/ts/AppTable.vue";
import { useBaseTable } from "@/hooks/tables/useTable";
import { ITemplatesData } from "@/store/interfaces/templates/ITemplateData";
import { templatesData } from "@/api/interfaces/templates/common";
import { formatDate } from "@/hooks/payments/usePayments";

type tableHeadCells = ITableHeadCell[];

export type templatesTableSort = {
  bySubject: sortItem;
};

export default defineComponent({
  name: "TemplatesTable",
  components: {
    AppTable
  },

  emits: {
    toggleEdit: null,
    delete: null,
    changeViewConfiguration: null
  },
  setup(props, { emit }) {
    const initialViewConfiguration = {
      page: 1,
      perPage: 10
    };

    const initialSortOptions: templatesTableSort = {
      bySubject: {
        order: sortOrders.turnOff,
        keyName: "subject",
        keyNameInModel: "subject"
      }
    };

    // rows actions
    const templateEditHandler = (data: ITemplatesData) => rowClickHandler(data);
    const templateDeleteHandler = (id: userId) => emit("delete", id);

    async function rowClickHandler(data: templatesData): Promise<void> {
      emit("toggleEdit", { status: true, data });
    }

    // table model
    const templatesList: ComputedRef<ITemplatesData[]> = computed(
      () => props.templatesList
    );

    const tableRefs = useBaseTable({
      initialViewConfiguration,
      initialSortOptions,
      model: templatesList,
      generateRows(templatesList: ITemplatesData[]): ITableBodyRow[] {
        return templatesList.map(
          ({
            id,
            subject,
            createdAt,
            createdBy,
            status,
            code,
            typeId
          }: ITemplatesData): ITableBodyRow => {
            let templateStatus = status ? "active" : "inactive";
            return {
              status: templateStatus,
              onClick: (): void => {
                templateEditHandler({
                  id,
                  subject,
                  createdAt,
                  createdBy,
                  status,
                  code,
                  typeId
                });
              },
              actions: [
                {
                  text: "Edit",
                  onClick: (): void => {
                    templateEditHandler({
                      id,
                      subject,
                      status,
                      createdAt,
                      createdBy,
                      code,
                      typeId
                    });
                  }
                },
                {
                  text: "Remove",
                  onClick: (): void => {
                    templateDeleteHandler(id);
                  }
                }
              ],
              cells: [
                {
                  label: subject
                },
                {
                  label: formatDate(createdAt)
                },
                {
                  infoWithImage: {
                    title: createdBy?.email,
                    image: createdBy?.image
                  } as infoWithImage
                },
                {
                  label: status ? "active" : "inactive"
                }
              ]
            };
          }
        );
      },
      onViewConfigurationChanged(
        viewConfiguration: ITableViewConfiguration
      ): void {
        // get data by parent component
        emit("changeViewConfiguration", viewConfiguration);
      }
    });

    const {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    } = tableRefs;

    return {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    };
  },

  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },

    templatesList: {
      type: Array as PropType<ITemplatesData[]>,
      required: true
    },

    sort: {
      type: Object as PropType<templatesTableSort>,
      required: true
    },

    parentViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10,
        search: "",
        sortField: "subject",
        sortType: "ASC"
      })
    }
  },

  computed: {
    tableHead(): tableHeadCells {
      const { bySubject } = this.currentSort;
      return [
        {
          size: "xl",
          sort: {
            keyName: bySubject.keyName,
            order: bySubject.order
          },

          label: "subject",
          id: "subject"
        },
        {
          size: "lg",
          label: "data created",
          id: "data-created"
        },
        {
          size: "xl",
          label: "created by",
          id: "created-by"
        },
        {
          size: "xs",
          label: "status",
          id: "status"
        }
      ];
    }
  }
});
